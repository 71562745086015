<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.keyword" placeholder="申请流水/离职理由" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.employees" placeholder="请选择申请人" filterable  multiple collapse-tags>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.status" placeholder="状态" filterable  multiple collapse-tags>
						<el-option v-for="(status, s) in statuses" :key="s" :label="status" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select slot="label" v-model="params.date_type" placeholder="请选择时间类型" filterable clearable>
						<el-option label="入职时间" value="hired"></el-option>
						<el-option label="离职时间" value="separated"></el-option>
						<el-option label="申请时间" value="created"></el-option>
					</el-select>
					<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getDepartures({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" ref="employee_table" class="bill scroll-wrapper absolute" height="100%" :data="tables" :size="theme.size">
				<el-table-column label="申请人" prop="employee.name" min-width="90"></el-table-column>
				<el-table-column label="申请流水" min-width="160">
					<el-link slot-scope="scope" type="primary" @click="$refs['departure'].open(scope.row);" :disabled="!$utils.update($api.URI_EMPLOYEES_DEPARTURES)">{{scope.row.no}}</el-link>
				</el-table-column>
				<el-table-column label="工作时长" min-width="60">
					<template slot-scope="scope">{{scope.row | diff_at}} 天</template>
				</el-table-column>
				<el-table-column label="离职理由" prop="reason" min-width="160"></el-table-column>
				<el-table-column label="入职时间" prop="hired_at" min-width="150"></el-table-column>
				<el-table-column label="离职时间" prop="separated_at" min-width="150"></el-table-column>
				<el-table-column label="申请时间" prop="created_at" min-width="150"></el-table-column>
				<el-table-column label="状态" min-width="60">
					<template slot-scope="scope">
						<el-tooltip effect="dark" placement="top" :content="'OA审核编号'+scope.row.approval.sp_no" v-if="scope.row.approval">
							<span>{{statuses[scope.row.status] || '未知'}}</span>
						</el-tooltip>
						<span v-else>{{statuses[scope.row.status] || '未知'}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="60">
					<template slot-scope="scope">
						<el-link type="primary" @click="delDeparture(scope.row)">删除</el-link>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getDepartures({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getDepartures({ ...params, page: v }) }"
				background>	
			</el-pagination>
		</main-table>
		<departure-show ref="departure" :status="statuses" @refresh="getDepartures(params)"></departure-show>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';
	import mainTable from '@/components/main-table';
	import departureShow from '@/pages/hrm/departures/show';

	export default {
		components: {
			mainTable,
			departureShow
		},
		computed: {
			...mapState(['theme'])
		},
		filters: {
			diff_at (t) {
				if (!t) return '无';
				return moment(t.separated_at).diff(t.hired_at, 'day');
			},
			format_at (t) {
				if (!t) return '无';
				return moment(t).format('Y-MM-DD');
			}
		},
		methods: {
			async getDepartures (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_EMPLOYEES_DEPARTURES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.tables = result.data;
				this.shops = result.shops;
				this.statuses = result.status;
				this.employees = result.employees;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			delDeparture ({ id, employee, separated_at }) {
				if (this.$moment().isAfter(separated_at)) return this.$message.error('已超过离职时间不能操作删除！');
				this.$confirm(employee.name + ' 的离职申请 ', '确定要删除吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleDepartures({action: 'delete', id});
				});
			},
			async handleDepartures (params) {
				const res = await this.$http.get(this.$api.URI_EMPLOYEES_DEPARTURES, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getDepartures(this.params);
				this.$message.success(msg);
			}
		},
		data() {
			return {
				employee: {},
				tables: [],
				shops: [],
				statuses: [],
				employees: [],
				params: {
					perPage: 10,
				}
			}
		},
		created () {
			this.getDepartures(this.params, true);
		}
	};
</script>